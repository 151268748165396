
.navbar {
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  margin: 15px;
}

.logo-link {
  text-decoration: none;
}

.navbar-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  max-width: 1200px;
  margin: 0 auto;
  background-color: #fff;
}

.logo {
  display: flex;
  align-items: center;
}

.logo-img {
  width: 170px;
  height: auto;
  margin-left: 20px;
}

.nav-links {
  display: flex;
  list-style: none;
}

.nav-links .nav-link {
  margin-right: 25px;
  color: blue;
  font-size: 12px;
}

.auth-btn {
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  background-color: #2110ff;
  color: #fff;
  padding: 3px 4px 2px 5px;
  margin-top: -2px;
}


.nav-link:hover{
  color: orange;
}
.auth-btn:hover{
  color:orange;
}
.nav-link::after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  bottom: -5px;
  left: 0;
  background-color: orange;
  transition: width 0.3s ease;
}

.nav-link:hover::after {
  width: 100%; 
}

