.seo-toolbox-container {
    text-align: center;
    padding: 40px 50px;
    background-color: #002244;
    font-family: "Poppins", sans-serif;
    color: #333;
  }
  
  .title {
    font-size: 36px;
    margin-bottom: 10px;
    color: white;

  }
  
  .subtitle {
    font-size: 18px;
    color: #f97316;
    margin-bottom: 30px;
  }
  
  .features-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
  }
  
  .feature-item {
    background: #fff;
    border: 1px solid #f3f4f6;
    border-radius: 8px;
    padding: 20px;
    text-align: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .icon {
    font-size: 60px;
    color: #f97316;
    margin-bottom: 15px;
  }
  
  h3 {
    font-size: 20px;
    margin-bottom: 10px;
  }
  
  p {
    font-size: 16px;
    color: #555;
  }
  
  .cta-button {
    margin-top: 30px;
    background-color: #f97316;
    color: #fff;
    border: none;
    padding: 15px 30px;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .cta-button:hover {
    background-color: #d65c13;
  }
  