.serp-preview-container {
    border: 1px solid #e0e0e0;
    padding: 10px;
    border-radius: 5px;
    background-color: #f9f9f9;
    margin-bottom: 20px;
    max-width: 600px;
}

.serp-preview-url {
    color: #006621;
    font-size: 14px;
    margin-bottom: 5px;
}

.serp-preview-title {
    font-size: 18px;
    color: #1a0dab;
    margin-bottom: 5px;
}

.serp-preview-description {
    font-size: 13px;
    color: #545454;
}

/* @media (max-width: 600px) { */
    .serp-preview-mobile-container {
      padding: 16px;
      font-family: Arial, sans-serif;
      background-color: #fff;
      border-radius: 8px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
      margin: 16px;
      max-width: 342px;
    }
  
    .serp-preview-mobile-url {
      /* color: #1a0dab; */
      color:#006621;
      font-size: 12px;
      margin-bottom: 8px;
      word-wrap: break-word;
    }
  
    .serp-preview-mobile-title {
      /* color: #000; */
      color: #1a0dab;
      font-size: 16px;
      /* font-weight: bold; */
      margin-bottom: 6px;
    }
  
    .serp-preview-mobile-description {
      color: #545454;
      font-size: 12px;
      line-height: 1.4;
      word-wrap: break-word;
    }
