.dashboard {
    width: 80%;
    margin: auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 10px;
    text-align: center;
    background-color: #f9f9f9;
  }
  
  .profile img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-bottom: 10px;
  }
  
  .profile h2 {
    margin: 10px 0;
    font-size: 1.5rem;
    color: #333;
  }
  
  .stats {
    margin-top: 20px;
    font-size: 1.1rem;
  }
  
  .stats p {
    margin: 5px 0;
  }
  
  .error {
    color: red;
    font-size: 1.2rem;
  }
  .dashboard table {
    width: 100%;
    margin-top: 20px;
    border-collapse: collapse;
    background-color: #fff;
  }
  
  .dashboard table th,
  .dashboard table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  .dashboard table th {
    background-color: #f4f4f4;
    color: #333;
  }
  
  .dashboard table tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .dashboard table tr:hover {
    background-color: #f1f1f1;
  }
  