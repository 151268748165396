.footer {
    /* background-color: #002244; */
    background-color: black;
    color: #ffffff;
    padding: 20px 100px;
    font-family: Arial, sans-serif;
    border-top: 4px solid orange;
  }
  
  .footer-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
  
  .footer-left {
    flex: 1;
    /* min-width: 250px; */
  }
  .footer-left img{
    width: 10rem;
    margin-bottom: 10px;

  }
  
  .footer-left p {
    margin-bottom: 15px;
    font-size: 14px;
    color:#fff;
  }
  
  .social-icons a {
    color: #ffffff;
    margin-right: 10px;
    font-size: 18px;
    text-decoration: none;
  }
  

  
  .footer-right {
    margin-right: 30px;
  }
  
  .footer-right h4 {
    font-size: 18px;
    margin-bottom: 10px;
  }
  
  .footer-right ul {
    list-style: none;
    padding: 0;
  }
  
  .footer-right ul li {
    margin-bottom: 8px;
  }
  
  .footer-right ul li a {
    color: #ffffff;
    text-decoration: none;
    font-size: 14px;
  }
  
  .footer-right ul li a:hover {
    text-decoration: underline;
  }
  
  .footer-bottom p{
    text-align: center;
    margin-top: 20px;
    font-size: 14px;
    color: #fff;
  }
  