*{
    margin: 0;
    padding:0;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
}
.about-cover{
    background-color: #f8f6f4;

}
.about-wrapper{
    margin: 0rem 7rem 3rem 7rem;
}
.about-main-text h1{
    text-align: center;
    /* background-color: #fff; */
    font-size: 3.5rem;
    color: black;
    /* padding-bottom: 0.5rem; */

}
.about-secondary-text h2{
    font-weight: 550;
    font-size: 1.2rem;
    padding-bottom: 0.4rem;
    padding-top: 1rem;
    color: black;
}
.about-secondary-text p{
    font-size: 0.9rem;
}

.about-secondary-text ul{
    /* padding-left: 13px; */
    padding:8px 0px 7px 13px;
    font-size: 0.85rem;
    color: black;

}
.about-secondary-text .bottom-text{
    padding-top: 2rem;
    font-size: 1.4rem;
}
.about-secondary-text .link{
    color: #ff6600;
    text-decoration: none;
}
.bottom-text{
    padding-bottom: 2rem;

}