* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.cover {
  padding-left: 50px;
  padding-right: 40px;
  background-color: #e18114ec;
  /* background-color: rgb(255, 0, 0); */
  /* font-family: serif; */
  font-family: "Poppins", sans-serif;
}

.seo-audit-container {
  padding: 25px 0px 0px 50px;
  color: white;
  font-size: 30px;
}

.header {
  display: flex;
  flex-direction: column;
  /* width: fit-content; */
}

.main-text {
  font-size: 70px;
  line-height: 80px;
}

.secondary-text {
  padding-top: 40px;
  font-size: 25px;
  line-height: 34px;
}

.input-container {
  padding-top: 30px;
  display: flex;
  margin-top: 20px;
  gap: 10px;
  width: 100%;
}

.input-container input {
  padding: 15px;
  color: black;
  font-size: large;
  border: 2px solid #f80;
  width: 60%;
  padding-left: 30px;
  background-color: rgba(255, 255, 255, 0.938);
}

.input-container input:focus {
  outline: none;
  border: 2px solid #f80;
  background-color: #fff;
  color: #000;
}

.start-button,
.plans-button {
  padding: 10px 20px;
  border: none;
  cursor: pointer;
}

.start-button {
  background-color: #0072b8;
  color: white;

  position: relative;
}
.start-button:disabled {
  background-color: #007a63;
  cursor: not-allowed;
}

.start-button .loading-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 16px;
  height: 16px;
  border: 4px solid transparent;
  border-top-color: #ffffff;
  border-radius: 50%;
  animation: button-loading-spinner 1s ease infinite;
}

@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }
  to {
    transform: rotate(1turn);
  }
}

.button__text.hidden {
  visibility: hidden;
  opacity: 0;
}

.subtext {
  margin-top: 10px;
  font-size: 14px;
  margin-bottom: 50px;
}

@media (max-width: 1200px) {
  .seo-audit-container {
    padding-left: 30px;
  }

  .header {
    width: 80vw;
  }

  .main-text {
    font-size: 60px;
    line-height: 70px;
  }

  .secondary-text {
    font-size: 28px;
    line-height: 30px;
    word-wrap: break-word;
  }

  .input-container input {
    /* width: 55%; */
    width: 80%;
  }

  .start-button {
    padding: 8px 18px;
  }
}

@media (max-width: 900px) {
  .cover {
    padding-left: 20px;
  }

  .seo-audit-container {
    padding-left: 20px;
  }

  .header {
    width: 90vw;
  }

  .main-text {
    font-size: 50px;
    line-height: 60px;
  }

  .secondary-text {
    font-size: 24px;
    line-height: 26px;
  }

  .input-container {
    flex-direction: column;
    width: 100%;
    align-items: center;
  }

  .input-container input {
    margin-left: -120px;
    width: 80%;
  }

  .start-button {
    width: 40%;
    margin-left: -140px;

    /* padding: 12px 20px; */
  }

  .subtext {
    font-size: 14px;
  }
}

@media (max-width: 600px) {
  .header {
    width: 95vw;
    padding-left: 10px;
  }

  .main-text {
    font-size: 40px;
    line-height: 50px;
  }

  .secondary-text {
    font-size: 20px;
    line-height: 22px;
  }

  .input-container input {
    margin-left: -30px;
    width: 90%;
  }

  .start-button {
    width: 70%;
    margin-left: -35px;

    padding: 15px 25px;
  }

  .subtext {
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .header {
    width: 85vw;
    padding-left: -10px;
  }
  .main-text {
    font-size: 30px;
    line-height: 40px;
  }

  .secondary-text {
    font-size: 18px;
    line-height: 20px;
    overflow-wrap: break-word;
  }

  .input-container input {
    width: 100%;
    /* background-color: white; */
  }

  .start-button {
    width: 80%;
    /* padding: 16px 30px; */
  }

  .subtext {
    font-size: 18px;
  }
}
/* 
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.loader div {
  width: 15px;
  height: 15px;
  margin-top: 10px;
  margin-bottom: -20px;
  border-radius: 50%;
  background-color: #ffffff;
  animation: rotate 1.2s infinite ease-in-out;
}


.loader div:nth-child(1) {
  animation-delay: -0.48s;
}
.loader div:nth-child(2) {
  animation-delay: -0.36s;
}
.loader div:nth-child(3) {
  animation-delay: -0.24s;
}
.loader div:nth-child(4) {
  animation-delay: -0.12s;
}
.loader div:nth-child(5) {
  animation-delay: 0;
} */

.progress-barr {
  margin-left: 80px;
  margin-top: 50px;
  width: 70%;
  height: 10px;
  background-color: #ddd;
  border-radius: 5px;
  overflow: hidden;
  /* display: flex;
  justify-content: center; */
}

.progress-bar-innerr {
  padding-left: 70px;

  width: 0%; 
  height: 100%;
  background-color: #4caf50;
  animation: progressAnimation 18s ease-in-out forwards;
}

.loading-containerr {
  text-align: center;
  color: white;
}

.loading-text {
  margin-top: 10px;
  margin-bottom: -20px;
  margin-left: -80px;
  font-size: 18px;
  font-weight: bold;
  animation: loadingTextAnimation 2s infinite; 
}

@keyframes progressAnimation {
  0% {
    width: 0%;
  }
  10% {
    width: 10%;
  }
  20% {
    width: 20%;
  }
  30% {
    width: 30%;
  }
  40% {
    width: 40%;
  }
  50% {
    width: 50%;
  }
  60% {
    width: 60%;
  }
  70% {
    width: 70%;
  }
  80% {
    width: 80%;
  }
  90% {
    width: 90%;
  }
  100% {
    width: 95%;
  }
}

@keyframes loadingTextAnimation {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
      10% {
    opacity: 0.1;
  }
}

@keyframes rotate {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}

.error-message {
  color: red;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: -33.5px;
} 