.under-construction-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f5f5f5;
  text-align: center;
  font-family: Arial, sans-serif;
}

.under-construction-heading {
  font-size: 2.5rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
}

.under-construction-message {
  font-size: 1.2rem;
  color: #555;
  margin-bottom: 30px;
}

.progress-bar {
  width: 50%;
  height: 10px;
  background-color: #ddd;
  border-radius: 5px;
  overflow: hidden;
}

.progress-bar-inner {
  width: 70%; /* You can adjust this width or animate it later */
  height: 100%;
  background-color: #4caf50;
  animation: progressAnimation 3s infinite;
}

@keyframes progressAnimation {
  0% {
    width: 0%;
  }
  50% {
    width: 50%;
  }
  100% {
    width: 95%;
  }
}
