.main-heading, .main-description {
    text-align: center; 
}
.landing-page-container1 {
    min-height: 100vh;
    /* background-color: #6366f1; */
    /* background-color: '#ff7f7f'; */
    display: flex;
    align-items: center;
    justify-content: center;
    /* padding: 3rem 1rem; */
    /* padding:0.7rem 1.5rem 3rem 1.5rem; */
    overflow: hidden;
    /* background-color: black; */

    position: relative;
    /* background-color: whitesmoke; */
    /* background-color: #003bfe; */

  }
  
  .decorative-circle1 {
    position: absolute;
    width: 24rem;
    height: 24rem;
    background-color: #8183f4;
    border-radius: 50%;
    opacity: 0.5;
  }
  
  .top-right-circle1 {
    top: 0;
    right: 0;
  }
  
  .bottom-left-circle1 {
    bottom: 0;
    left: 0;
  }
  
  .dot-pattern1 {
    position: absolute;
    top: 5rem;
    right: 5rem;
    display: grid;
    grid-template-columns: repeat(6, auto);
    gap: 0.5rem;
  }
  
  .dot1 {
    width: 0.25rem;
    height: 0.25rem;
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 50%;
  }
  
  .content-container1 {
    background-color: white;
    /* border-radius: 1rem; */
    /* padding: 2rem; */
    max-width: 80rem;
    width: 100%;
    /* box-shadow: 0 0 2rem rgba(0, 0, 0, 0.1); */
    position: relative;
    z-index: 10;
    /* padding: 2rem; */
    background-color: rgb(0, 0, 0);

  }