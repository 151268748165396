*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
}
.contact-page{
    display: flex;
    justify-content: space-between;
    /* padding-top: 2rem;
    padding-left: 1rem;
    padding-left: 1rem; */
    padding: 3rem;
    flex-wrap: wrap;
    align-items: center;
    background-color: #f8f6f4;

}
.contact-section{
    width: 50%;
    padding-right: 30px;

}
.contact-section h2{
    font-weight: 700;
    line-height: 1.2;
    
}
.contact-section h1{
     font-weight: 500;
    font-size: 3.5rem;
    padding-bottom: 1rem; 
    color: black;
}
.contact-section p{
    color-scheme: "light";
    line-height: 40px;
}
.contact-section a{
    color: #ff6600;
    text-decoration: none;
  }

.address h3{
    font-size: 18px;
    margin-top: 20px;
    font-weight: 600;
}
.address p{
    line-height:25px;
    font-weight: 500;
}

.image-section{
    max-width: 500px;
    height: auto;
}

.contact-form {
    margin-top: 20px;
  }
  .contact-form div {
    margin-bottom: 15px;
  }
  .contact-form label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: black;
  }
  .contact-form input,
  .contact-form textarea {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
    color: black;
  }
  .contact-form button {
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  .contact-form button:hover {
    background-color: #0056b3;
  }
  